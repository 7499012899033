import React from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { BiMap} from "@react-icons/all-files/bi/BiMap";
import { FiPhoneCall} from "@react-icons/all-files/fi/FiPhoneCall";
import { FiMail} from "@react-icons/all-files/fi/FiMail";
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../content/images/logo.png'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const HomePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const dr_adam_img = data.dr_adam_img?.childImageSharp?.fluid
  const dr_patterson_img = data.dr_patterson_img?.childImageSharp?.fluid
  const dr_charles_img = data.dr_charles_img?.childImageSharp?.fluid
  const section2_background = data.section2_background?.childImageSharp?.fluid
  const root_canal = data.root_canal?.childImageSharp?.fluid
  const endodontic_retreatment = data.endodontic_retreatment?.childImageSharp?.fluid
  const endodontic_surgery = data.endodontic_surgery?.childImageSharp?.fluid
  const big_tooth = data.big_tooth?.childImageSharp?.fluid
  const cracked_teeth = data.cracked_teeth?.childImageSharp?.fluid
  const traumatic_injuries = data.traumatic_injuries?.childImageSharp?.fluid
  const apicoectomy = data.apicoectomy?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section1_background = data.section1_background?.childImageSharp?.fluid
  const green_dots = data.green_dots?.childImageSharp?.fluid
  const section2_dots = data.section2_dots?.childImageSharp?.fluid
  const section3_background = data.section3_background?.childImageSharp?.fluid
  const contact_dots = data.contact_dots?.childImageSharp?.fluid
  const waves_img = data.waves_img?.childImageSharp?.fluid
  const border1_left = data.border1_left?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Meridian Endodontics</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Meridian Endodontics"/>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady. We are a dental practice dedicated exclusively to endodontic care. Don't hesitate to contact us at 317-846-4980."/>
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady. We are a dental practice dedicated exclusively to endodontic care. Don't hesitate to contact us at 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Meridian Endodontics"/>
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady. We are a dental practice dedicated exclusively to endodontic care. Don't hesitate to contact us at 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg hero-bg"
        fluid={hero_background}
        alt="Endodontics pc"
      >
        <div id="hero-section">
          <Container>
            <div className="col-lg-5">
              <h1 className="primary-text hero-heading">
                Specialists In Saving Teeth
              </h1>
              <p className="hero-text">
                The office of Dr. Adam B. Broady and Dr. Sam B. Patterson, specialists in saving teeth.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn">Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-7">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <BackgroundImage
        Tag="section"
        className="section1-bg hero-bg"
        fluid={section1_background}
        alt="Endodontics pc"
      >
        <section id="home-section-1">
          <Container>
            <div className="col-lg-12 text-center">
              <h2 className="primary-text">The Doctors</h2>
              <div className="bottom-border"></div>
            </div>
            <div className="col-lg-12 doctors-container">
              <div className="row align-items-center">
                <div className="col-lg-6 contents-left-img">
                  <Img fluid={green_dots} className="home-green-dots"/>
                  <Img fluid={dr_adam_img} className="dr-adam-img"/>
                </div>
                <div className="col-lg-6 contents-right">
                  <h4 className="primary-text">Dr. Adam B. Broady</h4>
                  <h5>DDS, MSD</h5>
                  <p>Dr. Broady grew up in Carmel, Indiana. He attended Indiana University in Bloomington, graduating with High Distinction while earning a B.A. in Biology. Dr. Broady went on to attend Indiana University’s School of Dentistry and was proudly inducted into the Omicron Kappa Upsilon Honor Society upon graduation.</p>
                  <Link to="/meet-us/meet-the-doctor"> <button> Learn More </button> </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 doctors-container">
              <div className="row align-items-center">
                <div className="col-lg-6 contents-left">
                  <Img fluid={dr_patterson_img} className="dr-charles-img-mobile"/>
                  <h4 className="primary-text">Dr. Sam B. Patterson</h4>
                  <h5>DDS, MSD</h5>
                  <p>Dr. Patterson was raised in Zionsville and graduated from Park Tudor High School. He went on to complete his undergraduate education at Miami University and obtained his Bachelor of Science degree in Business Management with a co-major in Premedical Sciences. He went on to complete a post-graduate Master of Science program at Indiana University Purdue University Indianapolis.</p>
                  <Link to="/meet-us/meet-the-doctor"> <button> Learn More </button> </Link>
                </div>
                <div className="col-lg-6 contents-right-img">
                  <Img fluid={green_dots} className="home-green-dots-2"/>
                  <Img fluid={dr_patterson_img} className="dr-charles-img"/>
                </div>
              </div>
            </div>
            <div className="col-lg-12 doctors-container">
              <div className="row align-items-center">
                <div className="col-lg-6 contents-left-img">
                  <Img fluid={green_dots} className="home-green-dots"/>
                  <Img fluid={dr_charles_img} className="dr-adam-img"/>
                </div>
                <div className="col-lg-6 contents-right">
                  <h4 className="primary-text">Dr. Charles L. Steffel</h4>
                  <h5>DDS, MSD</h5>
                  <p>Dr. Steffel officially retired from practice in May of 2022.</p>
                  {/* <Link to="/meet-us/meet-the-doctor"> <button> Learn More </button> </Link> */}
                </div>
              </div>
            </div>
        </Container>
      </section>
    </BackgroundImage>
      <section id="new-contents-section">
        <Container>
          <div className="col-lg-12">
            <div className="bottom-border"></div>
          </div>
          <div className="col-lg-12 left-border">
            <div className="border-left-1"></div>
            <p>
              Thank you for considering our office for your dental needs. Our endodontics office in Indianapolis specializes in saving teeth. Dr. Broady and Dr. Patterson are active members of the American Association of Endodontists and the American Dental Association.
            </p>
          </div>
          <div className="col-lg-12 left-border">
            <div className="border-left-1" id="border-2"></div>
            <p>
              Your dentist referred you to our office for the best endodontic care in the Indianapolis area. Meridian Endodontics is one of the most referred dental specialists in the state. Our team of experts, led by Dr. Broady, has extensive experience working together. In addition, several of our staff members have been a part of the dental office for over 20 years.
            </p>
          </div>
          <div className="col-lg-12 left-border">
            <div className="border-left-1" id="border-3"></div>
            <p>
              We always strive to provide professional dental care in a friendly and comfortable environment. Patients can expect compassionate services and unsurpassed quality with all treatments, retreatments, and surgical procedures.
            </p>
          </div>
          <div className="col-lg-12 container-box-2">
            <div className="row">
              <div className="col-lg-4 left-img">
                <Img fluid={big_tooth} className="big-tooth-img"/>
              </div>
              <div className="col-lg-8 text-center right-content">
                <Img fluid={contact_dots} className="contact-dots-img"/>
                <h3 className="primary-text">If you have additional questions outside the scope of our website about our endodontic services</h3>
                <p>Please contact us at <a href="tel:3178464980">317-846-4980.</a></p>
                <Link to="/contact-us/contact-information-office-map">
                  <button> Schedule An Appointment </button>
                </Link>
                <Link to="/patient-information/patient-registration">
                  <button id="btn-2"> Patient Registration </button>
                </Link>
              </div>
            </div>
            <Img fluid={waves_img} className="waves2-img"/>
          </div>
        </Container>
      </section>
      <section id="home-section-2">
      <BackgroundImage
        Tag="div"
        className="section2-bg"
        fluid={section2_background}
        alt="Endodontics pc"
      >
        <Container>
          <div className="col-lg-12 text-center">
            <h2 className="primary-text">What We Provide</h2>
            <div className="bottom-border"></div>
            <p className="heading-p">We hope that the information provided here answers many of your questions about endodontic treatment. If you would like additional information, please don’t hesitate to contact us at <a href="tel:3178464980">317-846-4980</a>.</p>
            <Img fluid={section2_dots} className="home-section2-dots"/>
          </div>

          <div className="col-lg-4 text-center flip-card">
            <div className="flip-card-inner">
              <div className="container-box flip-card-front p-b-0">
                <Img fluid={root_canal} className="root-canal-img"/>
                <div className="header-container">
                  <h4 className="primary-text">Endodontic (Root Canal) Treatment</h4>
                </div>
                <p>A root canal is one of the most common dental procedures with millions performed every year.</p>
              </div>
              <div className="container-box flip-card-back">
                <div className="flipback-content-container">
                  <p className="mt-0">
                    Where does the phrase originate? Endodontic comes from the Greek words "endo" and "odont," which means inside the tooth. While the mention might make you shudder, it's a practical and effective method for saving teeth. {/*Endodontists in Indianapolis save hundreds of teeth every year.*/}
                  </p>
                </div>
                <Link to="/procedures/root-canal-therapy">
                  <button> Learn More </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 text-center flip-card">
            <div className="flip-card-inner">
              <div className="container-box flip-card-front">
                <Img fluid={endodontic_retreatment} className="endodontic_retreatment-img"/>
                <div className="header-container">
                  <h4 className="primary-text">Endodontic Retreatment</h4>
                </div>
                <p>With proper care, most teeth that have had endodontic (root canal) treatment can last a lifetime.</p>
              </div>
              <div className="container-box flip-card-back pb-40">
                <div className="flipback-content-container">
                  <p id="large-content-p">
                    Most initial root canals don't need retreatment or further care. However, if the tooth fails to heal, other options are available. Endodontists in Indianapolis offer re-treatment as the second line of care. The pain or infection may return weeks, months, or years later.
                  </p>
                </div>
                <Link to="/procedures/endodontic-retreatment">
                  <button> Learn More </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 text-center flip-card">
            <div className="flip-card-inner">
              <div className="container-box flip-card-front">
                <Img fluid={endodontic_surgery} className="endodontic-surgery-img"/>
                <div className="header-container">
                  <h4 className="primary-text">Endodontic Surgery</h4>
                </div>
                <p>Sometimes nonsurgical endodontic (root canal) treatment alone cannot save a tooth.</p>
              </div>
              <div className="container-box flip-card-back pb-40">
                <div className="flipback-content-container">
                  <p>
                    Although endodontists in Indianapolis perform hundreds of root canals every year, not all are successful. A surgical procedure may be necessary to determine the cause. Additionally, endodontic surgery may relieve pain by removing damaged tissue.
                  </p>
                </div>
                <Link to="/procedures/apicoectomy">
                  <button> Learn More </button>
                </Link>
              </div>
            </div>
          </div>

        </Container>
        </BackgroundImage>
        <Container>
          <div className="col-lg-12 container-box-2">
            <div className="row">
              <div className="col-lg-4 left-img">
                <Img fluid={big_tooth} className="big-tooth-img"/>
              </div>
              <div className="col-lg-8 text-center right-content">
                <Img fluid={contact_dots} className="contact-dots-img"/>
                <h3 className="primary-text">Ready to Schedule a Consultation? </h3>
                <p>We provide the highest standard of professional care in a friendly, comfortable environment.</p>
                <Link to="/contact-us/contact-information-office-map">
                  <button> Schedule An Appointment </button>
                </Link>
                <Link to="/patient-information/patient-registration">
                  <button id="btn-2"> Patient Registration </button>
                </Link>
              </div>
            </div>
            <Img fluid={waves_img} className="waves2-img"/>
          </div>
        </Container>
      </section>
    <BackgroundImage
      Tag="section"
      className="section3-bg"
      fluid={section3_background}
      alt="Endodontics pc"
    >
      <section id="home-section-3">
        <Container>
          <div className="col-lg-12 text-center">
            <h2 className="primary-text">Procedures</h2>
            <div className="bottom-border"></div>
            <p>Today’s advanced endodontic treatments and materials can give you a healthier, more complete smile that can last you a lifetime.</p>
          </div>
          <div className="col-lg-12">
            <div className="row mt-5 align-items-center">

              <div className="col-lg-4 text-center flip-card">
                <div className="flip-card-inner">
                  <div className="container-box flip-card-front">
                    <Img fluid={root_canal} className="root-canal-img"/>
                    <div className="header-container-2">
                      <h4 className="primary-text">Root Canal Therapy</h4>
                    </div>
                    <p>A root canal is one of the most common dental procedures with millions performed every year.</p>
                  </div>
                  <div className="container-box flip-card-back">
                    <p>A Root Canal is one of the most common dental procedures performed, well over 14 million every year. This simple treatment can save your natural teeth and prevent the need of...</p>
                    <Link to="/procedures/root-canal-therapy"> <button> Learn More </button> </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center flip-card flip-card-2">
                <div className="flip-card-inner">
                  <div className="container-box flip-card-front">
                    <Img fluid={endodontic_retreatment} className="endodontic_retreatment-img"/>
                    <div className="header-container-2">
                      <h4 className="primary-text">Endodontic Retreatment</h4>
                    </div>
                    <p>With proper care, most teeth that have had endodontic (root canal) treatment can last a lifetime.</p>
                  </div>
                  <div className="container-box flip-card-back">
                    <p>Yet, a tooth that has received treatment may fail to heal or pain may continue to exist. Sometimes, the pain may occur months or years after treatment. If so, endodontic retreatment...</p>
                    <Link to="/procedures/endodontic-retreatment"> <button> Learn More </button> </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center flip-card">
                <div className="flip-card-inner">
                  <div className="container-box flip-card-front">
                    <Img fluid={apicoectomy} className="apicoectomy-img"/>
                    <div className="header-container-2">
                      <h4 className="primary-text">Apicoectomy</h4>
                    </div>
                    <p>Generally, a root canal is all that is needed to save teeth with injured pulp from extraction.</p>
                  </div>
                  <div className="container-box flip-card-back">
                    <p>Endodontic surgery can be used to locate fractures or hidden canals that do not appear on x-rays but still manifest pain in the tooth. Damaged root surfaces or the surrounding bone...</p>
                    <Link to="/procedures/apicoectomy"> <button> Learn More </button> </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-4 text-center flip-card">
                <div className="flip-card-inner">
                  <div className="container-box flip-card-front">
                    <Img fluid={cracked_teeth} className="cracked-teeth-img"/>
                    <div className="header-container-2">
                      <h4 className="primary-text">Cracked Teeth</h4>
                    </div>
                    <p>Cracked teeth demonstrate many types of symptoms, including pain when chewing, temperature sensitivities, or even the release of biting pressure.</p>
                  </div>
                  <div className="container-box flip-card-back">
                    <p>It is also common for pain to come and go, making it difficult to diagnose the cause of discomfort. Chewing can cause movement of the cracked pieces of your tooth...</p>
                    <Link to="/procedures/cracked-teeth"> <button> Learn More </button> </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 text-center flip-card">
                <div className="flip-card-inner">
                  <div className="container-box flip-card-front">
                    <Img fluid={traumatic_injuries} className="traumatic-injuries-img"/>
                    <div className="header-container-2">
                      <h4 className="primary-text">Traumatic Injuries</h4>
                    </div>
                    <p>Injuries to the mouth can cause teeth to be pushed back into their sockets. Your endodontist or general dentist may reposition and stabilize your tooth.</p>
                  </div>
                  <div className="container-box flip-card-back">
                    <p>Your Endodontist or general dentist may reposition and stabilize your tooth. Root canal treatment is usually started within a few weeks of the injury and a medication, such as...</p>
                    <Link to="/procedures/traumatic-injuries"> <button> Learn More </button> </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </BackgroundImage>
    <section id="home-section-4">
      <ContactForm />
    </section>
  </Layout>
  )
}

export default HomePage

export const query = graphql`
  query HomePageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        adsense
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero_background: file(name: { eq: "hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "home-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_adam_img: file(name: { eq: "dr-adam" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_patterson_img: file(name: { eq: "dr-patterson" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dr_charles_img: file(name: { eq: "dr-charles" }) {
      childImageSharp {
        fluid(maxWidth: 417, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_background: file(name: { eq: "section-2bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section1_background: file(name: { eq: "home-section1-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_background: file(name: { eq: "home-section3-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    root_canal: file(name: { eq: "root-canal" }) {
      childImageSharp {
        fluid(maxWidth: 79, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    endodontic_retreatment: file(name: { eq: "endodontic" }) {
      childImageSharp {
        fluid(maxWidth: 58, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    endodontic_surgery: file(name: { eq: "endodontic-surgery" }) {
      childImageSharp {
        fluid(maxWidth: 54, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    big_tooth: file(name: { eq: "big-tooth" }) {
      childImageSharp {
        fluid(maxWidth: 472, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cracked_teeth: file(name: { eq: "cracked-teeth-img" }) {
      childImageSharp {
        fluid(maxWidth: 95, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    traumatic_injuries: file(name: { eq: "traumatic-injuries-img" }) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    apicoectomy: file(name: { eq: "apicoectomy-img" }) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    green_dots: file(name: { eq: "green-dots" }) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_dots: file(name: { eq: "section2-dots" }) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    contact_dots: file(name: { eq: "contact-dots" }) {
      childImageSharp {
        fluid(maxWidth: 108, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    waves_img: file(name: { eq: "waves4" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
